import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><inlineCode parentName="p">{`TabButton`}</inlineCode>{` is used for selectable entities, such as slots.`}</p>
    <h2>{`Using with icons`}</h2>
    <p>{`TabButton supports displaying icon with `}<inlineCode parentName="p">{`icon`}</inlineCode>{` prop`}</p>
    <h2>{`Default examples`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<div style={{ display: 'flex', flexDirection: 'column', gap: '8px', alignItems: 'flex-start' }}>
  <TabButton scale='sm'>TabButton with sm scale</TabButton>
  <TabButton>TabButton with md scale</TabButton>
  <TabButton active scale='lg'>Active TabButton with lg scale</TabButton>
  <TabButton disabled>Disabled TabButton</TabButton>
  <TabButton active disabled>Disabled active TabButton</TabButton>
  <TabButton scale='sm' icon='plus-circled'>TabButton with icon and sm scale</TabButton>
  <TabButton scale='lg' active icon='plus-circled'>Active TabButton with icon and lg scale</TabButton>
</div>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p><inlineCode parentName="p">{`TabButton`}</inlineCode>{` component get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{`, `}<inlineCode parentName="p">{`LAYOUT`}</inlineCode>{`, `}<inlineCode parentName="p">{`TYPOGRAPHY`}</inlineCode>{`, `}<inlineCode parentName="p">{`ICON`}</inlineCode>{` and `}<inlineCode parentName="p">{`BORDER`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <p>{`Native `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` HTML attributes are forwarded to the underlying React `}<inlineCode parentName="p">{`button`}</inlineCode>{` component and are not listed below.`}</p>
    <h3>{`Some notable system props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`as`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`button`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`sets the HTML tag for the component`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Main component props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Prop name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'opacityPrimary'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`opacityPrimary`}</inlineCode>{` results in different tab button styles`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`active`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`true`}</inlineCode>{` or `}<inlineCode parentName="td">{`false`}</inlineCode>{` showing whether the element is active`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`scale`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`'md'`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`a value of `}<inlineCode parentName="td">{`sm`}</inlineCode>{`, `}<inlineCode parentName="td">{`md`}</inlineCode>{`, `}<inlineCode parentName="td">{`lg`}</inlineCode>{` resulting in different tab button sizes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`icon`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "/atoms/Icon"
            }}>{`Icon`}</a>{` name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      